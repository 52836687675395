// extracted by mini-css-extract-plugin
export var column = "styles-module--column--22d2f";
export var columnFlex = "styles-module--columnFlex--06110";
export var columnMarqueeDown = "styles-module--columnMarqueeDown--da1e2";
export var columnMarqueeUp = "styles-module--columnMarqueeUp--c1545";
export var container = "styles-module--container--10de0";
export var illustration = "styles-module--illustration--75f18";
export var illustrationFigure = "styles-module--illustrationFigure--05de4";
export var illustrationImg = "styles-module--illustrationImg--fa3d5";
export var marqueeDown = "styles-module--marquee-down--9c17c";
export var marqueeUp = "styles-module--marquee-up--ac2a7";
export var sparkle = "styles-module--sparkle--be772";