import React from 'react';
import {
  Imagery,
  LayoutProvider,
  SliceHeader,
  TextImageGrid,
  WebsitePrototype
} from '@lam-agency/toolkit/components';
import { useMVT } from '@lam-agency/toolkit/stores';
import { parseImagesFromFigma } from '@lam-agency/toolkit/utils/figma';
import IllustrationMarquee from './IllustrationMarquee';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      heading?: string;
      images: string[];
      'body/1': string;
      'body/2': string;
    };
  };
}

const FigmaGallery = ({ data: { fields } }: IProps) => {
  const { projectString } = useMVT();

  const { heading, images } = fields || {};

  const body1 = fields['body/1'];
  const body2 = fields['body/2'];

  const imagery = parseImagesFromFigma(projectString, images);

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} data={{ heading }} />
      </LayoutProvider>

      <IllustrationMarquee />

      {/* {imagery?.[0]?.image && (
          <Imagery
            data={{
              image: imagery[0].image,
              button: { text: 'Download PNG', icon: 'download' }
            }}
          />
        )} */}

      {imagery?.[1]?.image && imagery?.[2]?.image && (
        <TextImageGrid
          body={body1}
          bodyPosition="right"
          items={[imagery[1], imagery[2]]}
        />
      )}

      <WebsitePrototype />

      {imagery?.[3]?.image && (
        <TextImageGrid body={body2} bodyPosition="left" items={[imagery[3]]} />
      )}
    </LayoutProvider>
  );
};

export default FigmaGallery;
