import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import gsap from 'gsap';

import * as styles from './styles.module.scss';

import banana from './../assets/banana.png';
import dumpling from './../assets/dumpling.png';
import roll from './../assets/roll-thing.png';
import watermelon from './../assets/watermelon.png';

import sparkle1 from './../assets/big-sparkle.png';
import sparkle2 from './../assets/small-sparkle.png';
import sparkle3 from './../assets/sparkles.png';

const Illustration = ({ image }: { image: string }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const sparkleRef1 = useRef<HTMLDivElement>(null);
  const sparkleRef2 = useRef<HTMLDivElement>(null);
  const sparkleRef3 = useRef<HTMLDivElement>(null);

  const handleHover = () => {
    gsap.killTweensOf(imgRef.current);
    gsap.to(imgRef.current, { scale: 1.1, duration: 0.3 });
  };

  const handleClick = () => {
    const sparkles = [
      sparkleRef1.current,
      sparkleRef2.current,
      sparkleRef3.current
    ];

    gsap.killTweensOf(imgRef.current);
    gsap.to(imgRef.current, {
      scale: 1.5,
      ease: 'elastic.out(1, 0.3)',
      duration: 0.75
    });

    sparkles.forEach((sparkle, sparkleIndex) => {
      let x;
      let y;
      let rotate;

      switch (sparkleIndex) {
        case 0:
          x = `-75%`;
          y = `-80%`;
          rotate = 10;
          break;
        case 1:
          x = `100%`;
          y = `-120%`;
          rotate = 15;
          break;
        case 2:
          x = `150%`;
          y = `70%`;
          rotate = -10;
          break;
      }

      gsap.set(sparkle, { visibility: 'visible' });

      gsap.fromTo(
        sparkle,
        { x: 0, y: 0, rotate: 0, opacity: 0, scale: 0.5 },
        {
          x,
          y,
          rotate,
          opacity: 1,
          scale: 1,
          duration: 0.75,
          ease: 'power2.out',
          onComplete: () => {
            gsap.to(sparkle, {
              x: 0,
              y: 0,
              rotate: 0,
              duration: 0.3,
              delay: 0.2
            });

            gsap.to(sparkle, {
              opacity: 0,
              duration: 0.25,
              delay: 0,
              onComplete: () => {
                gsap.set(sparkle, { visibility: 'hidden' });
              }
            });
          }
        }
      );
    });
  };

  useEffect(() => {
    gsap.set(sparkleRef1, { opacity: 0, visibility: 'hidden' });
    gsap.set(sparkleRef2, { opacity: 0, visibility: 'hidden' });
    gsap.set(sparkleRef3, { opacity: 0, visibility: 'hidden' });
  }, [sparkleRef1, sparkleRef2, sparkleRef3]);

  return (
    <div className={styles.illustration}>
      <figure className={styles.illustrationFigure}>
        <img
          className={styles.illustrationImg}
          src={image}
          ref={imgRef}
          onMouseEnter={handleHover}
          onMouseLeave={() =>
            gsap.to(imgRef.current, { scale: 1, duration: 0.3 })
          }
          onClick={handleClick}
        />
      </figure>

      <div className={cn(styles.sparkle)}>
        <figure ref={sparkleRef1}>
          <img className={cn(styles.sparkle1)} src={sparkle1} />
        </figure>
      </div>

      <div className={cn(styles.sparkle)}>
        <figure ref={sparkleRef2}>
          <img className={cn(styles.sparkle2)} src={sparkle2} />
        </figure>
      </div>

      <div className={cn(styles.sparkle)}>
        <figure ref={sparkleRef3}>
          <img className={cn(styles.sparkle3)} src={sparkle3} />
        </figure>
      </div>
    </div>
  );
};

const ColumnFruit = () => {
  return (
    <div className={cn(styles.column)}>
      <div className={cn(styles.columnFlex, styles.columnMarqueeUp)}>
        <Illustration image={banana} />
        <Illustration image={watermelon} />
        <Illustration image={banana} />
        <Illustration image={watermelon} />
        <Illustration image={banana} />
        <Illustration image={watermelon} />
        <Illustration image={banana} />
        <Illustration image={watermelon} />
      </div>
    </div>
  );
};

const ColumnFilth = () => {
  return (
    <div className={cn(styles.column)}>
      <div className={cn(styles.columnFlex, styles.columnMarqueeDown)}>
        <Illustration image={dumpling} />
        <Illustration image={roll} />
        <Illustration image={dumpling} />
        <Illustration image={roll} />
        <Illustration image={dumpling} />
        <Illustration image={roll} />
        <Illustration image={dumpling} />
        <Illustration image={roll} />
      </div>
    </div>
  );
};

const IllustrationMarquee = () => {
  return (
    <div className={styles.container}>
      <ColumnFilth />
      <ColumnFruit />
      <ColumnFilth />
      <ColumnFruit />
      <ColumnFilth />
    </div>
  );
};

export default IllustrationMarquee;
